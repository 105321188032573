<template>
    <div>
        <List>
            <router-link :to="{ name: 'lsvaOverview' }">
                <ListItem>
                    <div class="l-inline l-gap-3 l-center-v">
                        <div>
                            <DashboardIcon width="24" height="24" />
                        </div>

                        <div>{{ $t('overview') }}</div>
                    </div>
                </ListItem>
            </router-link>
        </List>
    </div>
</template>

<script>
import DashboardIcon from '@/components/icons/DashboardIcon'
import List from '@/components/List'
import ListItem from '@/components/ListItem'

export default {
    name: 'LsvaMenu',
    components: {
        DashboardIcon,
        List,
        ListItem,
    },
}
</script>

<i18n>
{
    "en": {
        "overview": "Overview"
    },
    "de": {
        "overview": "Übersicht"
    },
    "fr": {
        "overview": "Aperçu"
    },
    "it": {
        "overview": "Sommario"
    }
}
</i18n>

<style lang="scss" scoped>
a {
    &:first-child {
        &.router-link-exact-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }

    &:not(:first-child) {
        &.router-link-active {
            .list-item {
                background-color: $color-gray-light;
            }
        }
    }
}
</style>
