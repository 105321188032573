<template>
    <div class="l-padded">
        <h3 class="t-title">
            {{ $t('heavyVehicleTax') }}
        </h3>

        <div class="l-padded-y panels">
            <LsvaFilter
                :marker-dates="['2024-12-10', '2024-12-11']"
                @date-selected="onDateSelect"
            />
        </div>
    </div>
</template>

<script>
import LsvaFilter from '@/components/LsvaFilter.vue'

export default {
    name: 'LsvaOverviewView',
    components: {
        LsvaFilter,
    },
    methods: {
        onDateSelect(date) {
            console.log('Selected date:', date)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "heavyVehicleTax": "Heavy Vehicle Tax"
    },
    "de": {
        "heavyVehicleTax": "Schwerverkehrsabgabe"
    },
    "fr": {
        "heavyVehicleTax": "Taxe sur les poids lourds"
    },
    "it": {
        "heavyVehicleTax": "Tassa sui veicoli pesanti"
    }
}
</i18n>
