import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { commonService } from '@/service/store.service'

const DashboardLayout = () => import('@/layouts/dashboard.vue')
const LsvaLayout = () => import('@/layouts/lsva.vue')
const PrivateLayout = () => import('@/layouts/private.vue')
const PublicLayout = () => import('@/layouts/public.vue')
const RulesLayout = () => import('@/layouts/rules.vue')
const SharingLayout = () => import('@/layouts/sharing.vue')

const AnimalActivityChartView = () =>
    import('../components/asset-types/animal/views/AnimalActivityChartView')
const AssetChartsCombinedView = () =>
    import('../components/views/AssetChartsCombinedView')
const AssetChartsView = () => import('../components/views/AssetChartsView')
const AssetCustomMeasurementsChartView = () =>
    import('../components/views/AssetCustomMeasurementsChartView')
const AssetDetailView = () => import('../components/views/AssetDetailView')
const AssetFenceGuardStatusChartView = () =>
    import('../components/views/AssetFenceGuardStatusChartView')
const AssetListView = () => import('../components/views/AssetListView')
const AssetLocationHistory = () =>
    import('../components/views/AssetLocationHistory')
const AssetSchemeView = () => import('../components/views/AssetSchemeView')
const AssetStatusChartView = () =>
    import('../components/views/AssetStatusChartView')
const AssetTachographView = () =>
    import('../components/views/AssetTachographView')
const AssetTypeListView = () => import('../components/views/AssetTypeListView')
const BinFillLevelChartView = () =>
    import('../components/views/BinFillLevelChartView')
const BleHistoryChartView = () =>
    import('../components/views/BleHistoryChartView')
const CM2ChartsView = () =>
    import('../components/asset-types/cm2-humidity/views/CM2ChartsView')
const ChangePasswordView = () =>
    import('../components/views/ChangePasswordView')
const ConnectionHeatmapView = () =>
    import('../components/views/ConnectionHeatmapView')
const ConstructionProjectDetailsView = () =>
    import('../components/views/ConstructionProjectDetailsView')
const ConstructionProjectReportView = () =>
    import('../components/views/ConstructionProjectReportView')
const ConstructionProjectsView = () =>
    import('../components/views/ConstructionProjectsView')
const CreateLocationView = () =>
    import('../components/views/CreateLocationView')
const DashboardAssetsView = () =>
    import('../components/views/DashboardAssetsView')
const DashboardGenericChartView = () =>
    import('../components/views/DashboardGenericChartView')
const DashboardLocationHistoryView = () =>
    import('../components/views/DashboardLocationHistoryView')
const DashboardMaintenanceFormView = () =>
    import('../components/views/DashboardMaintenanceFormView')
const DashboardMaintenanceHistoryView = () =>
    import('../components/views/DashboardMaintenanceHistoryView')
const DashboardOverviewView = () =>
    import('../components/views/DashboardOverviewView')
const DashboardReportingFormView = () =>
    import('../components/views/DashboardReportingFormView')
const DashboardReportingHistoryView = () =>
    import('../components/views/DashboardReportingHistoryView')
const DashboardRfidView = () => import('../components/views/DashboardRfidView')
const DashboardSettingsView = () =>
    import('../components/views/DashboardSettingsView')
const DashboardTachographHistoryView = () =>
    import('../components/views/DashboardTachographHistoryView')
const DashboardUsersView = () =>
    import('../components/views/DashboardUsersView')
const EditAssetView = () => import('../components/views/EditAssetView')
const EditLocationView = () => import('../components/views/EditLocationView')
const LocationDetailView = () =>
    import('../components/views/LocationDetailView')
const LocationHistoryChartView = () =>
    import('../components/views/LocationHistoryChartView')
const LoginView = () => import('../components/views/LoginView')
const LsvaOverviewView = () => import('../components/views/LsvaOverviewView')
const MessageStreamView = () => import('../components/views/MessageStreamView')
const NavigationView = () => import('../components/views/NavigationView')
const NetworkChartView = () => import('../components/views/NetworkChartView')
const PasswordResetView = () => import('../components/views/PasswordResetView')
const RuleEditView = () => import('../components/views/redesigned/RuleEditView')
const RuleListView = () => import('../components/views/redesigned/RuleListView')
const RuleTemplatesView = () =>
    import('../components/views/redesigned/RuleTemplatesView')
const RunningTimeChartView = () =>
    import('../components/views/RunningTimeChartView')
const SearchView = () => import('../components/views/SearchView')
const SettingsView = () => import('../components/views/SettingsView')
const SharedAssetDetailsView = () =>
    import('../components/views/SharedAssetDetailsView')
const TreeMoistureChartsView = () =>
    import(
        '../components/asset-types/tree-moisture/views/TreeMoistureChartsView'
    )
const TreeTemperatureChartsView = () =>
    import(
        '../components/asset-types/tree-moisture/views/TreeTemperatureChartsView'
    )

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/map',
            component: PrivateLayout,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'search',
                    component: SearchView,
                    children: [
                        {
                            path: 'animal-activity-chart',
                            name: 'globalAnimalActivityChart',
                            component: AnimalActivityChartView,
                            beforeEnter: (to, from, next) => {
                                if (store.getters['auth/hasActivityAccess']) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                        },
                    ],
                },
                {
                    path: 'navigation',
                    name: 'navigation',
                    component: NavigationView,
                },
                {
                    path: 'connection-heatmap',
                    name: 'connectionHeatmap',
                    component: ConnectionHeatmapView,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['auth/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: SettingsView,
                },
                {
                    path: 'change-password',
                    name: 'changePassword',
                    component: ChangePasswordView,
                },
                {
                    path: 'location/create',
                    name: 'createlocation',
                    component: CreateLocationView,
                },
                {
                    path: 'location/:id',
                    name: 'location',
                    component: LocationDetailView,
                    props: true,
                    children: [
                        {
                            path: 'animal-activity-chart',
                            name: 'locationAnimalActivityChart',
                            component: AnimalActivityChartView,
                            props: true,
                            meta: { isChart: true },
                            beforeEnter: (to, from, next) => {
                                if (store.getters['auth/hasActivityAccess']) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                        },
                        {
                            path: 'history-chart',
                            name: 'locationHistoryChart',
                            component: LocationHistoryChartView,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters[
                                        'auth/hasLocationHistoryAccess'
                                    ]
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                            meta: { isChart: true },
                        },
                    ],
                },
                {
                    path: 'location/:locationId/connection-heatmap',
                    name: 'locationConnectionHeatmap',
                    component: ConnectionHeatmapView,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['auth/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'location/:id/edit',
                    name: 'editlocation',
                    component: EditLocationView,
                    props: true,
                },
                {
                    path: 'location/:locationid/:id',
                    name: 'locationDetailAssetDetail',
                    component: AssetDetailView,
                    props: true,
                },
                {
                    path: 'assets',
                    name: 'allAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_ALL' },
                },
                {
                    path: 'asset-types',
                    name: 'assetTypes',
                    component: AssetTypeListView,
                },
                {
                    path: 'asset-types/:assetType',
                    name: 'assetTypeAssets',
                    component: AssetListView,
                    props: { type: 'ASSET_TYPE' },
                },
                {
                    path: 'lost-assets',
                    name: 'lostAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_LOST' },
                },

                {
                    path: 'low-battery-assets',
                    name: 'lowBatteryAssets',
                    component: AssetListView,
                    props: { type: 'ASSETS_LOW_BATTERY' },
                },
                {
                    path: 'assets/:id',
                    alias: [
                        'asset-types/:assetType/:id',
                        'lost-assets/:id',
                        'low-battery-assets/:id',
                    ],
                    name: 'detail',
                    component: AssetDetailView,
                    props: true,
                    children: [
                        {
                            path: 'scheme',
                            name: 'assetScheme',
                            component: AssetSchemeView,
                        },
                        {
                            path: 'status-chart/:dataType/:labelPair',
                            name: 'statuschartview',
                            component: AssetStatusChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'tachograph',
                            name: 'tachograph',
                            component: AssetTachographView,
                            meta: { isChart: true },
                        },
                        {
                            path: 'tree-history-moisture-chart',
                            name: 'treehistorymoisturechart',
                            component: TreeMoistureChartsView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'tree-history-temperature-chart',
                            name: 'treehistorytemperaturechart',
                            component: TreeTemperatureChartsView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'cm2-simple-chart',
                            name: 'cm2simplechart',
                            component: CM2ChartsView,
                            props: route => ({
                                assetType: 'cm2-simple',
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'cm2-humidity-chart',
                            name: 'cm2humiditychart',
                            component: CM2ChartsView,
                            props: route => ({
                                assetType: 'cm2-humidity',
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'fence-guard-status-chart',
                            name: 'fenceGuardStatusChart',
                            component: AssetFenceGuardStatusChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'location-history-chart',
                            name: 'assetLocationHistoryChart',
                            component: LocationHistoryChartView,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters[
                                        'auth/hasLocationHistoryAccess'
                                    ]
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                            meta: { isChart: true },
                        },
                        {
                            path: 'beacon-history-chart',
                            name: 'assetBeaconHistoryChart',
                            component: BleHistoryChartView,
                            meta: { isChart: true },
                        },
                        {
                            path: 'gateway-history-chart',
                            name: 'assetGatewayHistoryChart',
                            component: BleHistoryChartView,
                            meta: { isChart: true },
                        },
                        {
                            path: 'sbb-bin-chart',
                            name: 'sbbbinchart',
                            component: BinFillLevelChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'dynamic-bin-chart',
                            name: 'dynamicbinchart',
                            component: BinFillLevelChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'animal-activity-chart',
                            name: 'animalactivitychart',
                            component: AnimalActivityChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'combined-chart',
                            name: 'chartsCombined',
                            component: AssetChartsCombinedView,
                            beforeEnter: (to, from, next) => {
                                if (to.query.measurement?.length) {
                                    next()
                                } else {
                                    next({
                                        name: 'detail',
                                        params: { id: to.params.id },
                                    })
                                }
                            },
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'measurements-chart/:measurement',
                            name: 'chartsMeasurements',
                            component: AssetCustomMeasurementsChartView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                        {
                            path: 'network-chart',
                            name: 'networkChart',
                            component: NetworkChartView,
                            props: true,
                            beforeEnter: (to, from, next) => {
                                if (store.getters['auth/hasDebugAccess']) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                            meta: { isChart: true },
                        },
                        {
                            path: 'running-time-chart',
                            name: 'runningTimeChart',
                            component: RunningTimeChartView,
                            props: true,
                            beforeEnter: (to, from, next) => {
                                if (
                                    store.getters['auth/hasRunningTimeAccess']
                                ) {
                                    next()
                                } else {
                                    next(false)
                                }
                            },
                            meta: { isChart: true },
                        },
                        {
                            path: ':dataType-chart',
                            name: 'charts',
                            component: AssetChartsView,
                            props: route => ({
                                ...route.params,
                                ...route.query,
                            }),
                            meta: { isChart: true },
                        },
                    ],
                },
                {
                    path: 'assets/:assetId/connection-heatmap',
                    name: 'assetConnectionHeatmap',
                    component: ConnectionHeatmapView,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if (store.getters['auth/isSuperuser']) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: 'assets/:id/edit',
                    alias: [
                        'asset-types/:assetType/:id/edit',
                        'lost-assets/:id/edit',
                        'low-battery-assets/:id/edit',
                    ],
                    name: 'editAsset',
                    component: EditAssetView,
                    props: true,
                },
                {
                    path: 'assets/:id/location-history',
                    name: 'locationhistory',
                    component: AssetLocationHistory,
                    props: true,
                },
                {
                    path: 'assets/:id/trip-history',
                    name: 'triphistory',
                    component: AssetLocationHistory,
                    props: true,
                },
                {
                    path: 'deveui/:deveui',
                    beforeEnter: (to, from, next) => {
                        commonService.populateData().then(() => {
                            const deveui = to.params.deveui
                            const tracker = store.state.tracker.trackers.find(
                                entry => entry.deveui === deveui
                            )
                            if (tracker) {
                                next(`/map/assets/${tracker.id}`)
                            } else {
                                console.log('No tracker found for given DevEUI')
                                next('/map')
                            }
                        })
                    },
                },
            ],
        },

        {
            path: '/dashboard',
            component: DashboardLayout,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) =>
                !store.getters['auth/hasDashboardAccess']
                    ? next(false)
                    : next(),
            children: [
                {
                    path: '',
                    name: 'dashboardOverview',
                    component: DashboardOverviewView,
                },
                {
                    path: 'assets',
                    name: 'dashboardAssets',
                    component: DashboardAssetsView,
                },
                {
                    path: 'construction-projects',
                    name: 'constructionProjectList',
                    component: ConstructionProjectsView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasConstructionProjectAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'construction-projects/create',
                    name: 'constructionProjectCreate',
                    component: ConstructionProjectDetailsView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasConstructionProjectAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'construction-projects/:id',
                    name: 'constructionProjectDetails',
                    component: ConstructionProjectDetailsView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasConstructionProjectAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'construction-projects/:projectId/report/:reportId?',
                    name: 'constructionProjectReport',
                    component: ConstructionProjectReportView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasConstructionProjectAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'generic-chart',
                    name: 'dashboardGenericChart',
                    component: DashboardGenericChartView,
                },
                {
                    path: 'location-history',
                    name: 'dashboardLocationHistoryChart',
                    component: DashboardLocationHistoryView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasLocationHistoryAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'maintenance',
                    name: 'dashboardMaintenanceForm',
                    component: DashboardMaintenanceFormView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasMaintenanceAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'maintenance-history',
                    name: 'dashboardMaintenanceHistory',
                    component: DashboardMaintenanceHistoryView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasMaintenanceAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'reporting',
                    name: 'dashboardReportingForm',
                    component: DashboardReportingFormView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasReportingAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'reports',
                    name: 'dashboardReportingList',
                    component: DashboardReportingHistoryView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasReportingAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'scan-rfid',
                    name: 'dashboardRfid',
                    component: DashboardRfidView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasRfidAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'settings',
                    name: 'dashboardSettings',
                    component: DashboardSettingsView,
                },
                {
                    path: 'tachograph',
                    name: 'dashboardTachographHistory',
                    component: DashboardTachographHistoryView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasTachographAccess']
                            ? next()
                            : next(false),
                },
                {
                    path: 'users',
                    name: 'dashboardUsers',
                    component: DashboardUsersView,
                    beforeEnter: (to, from, next) =>
                        store.getters['auth/hasUserManagementAccess']
                            ? next()
                            : next(false),
                },
            ],
        },

        {
            path: '/lsva',
            component: LsvaLayout,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) =>
                !store.getters['auth/hasLsvaAccess'] ? next(false) : next(),
            children: [
                {
                    path: '',
                    name: 'lsvaOverview',
                    component: LsvaOverviewView,
                },
            ],
        },

        {
            path: '/message-stream',
            name: 'messageStream',
            component: MessageStreamView,
        },

        {
            path: '/rules',
            component: RulesLayout,
            meta: {
                requiresAuth: true,
            },
            beforeEnter: (to, from, next) =>
                !store.getters['auth/hasRulesAccess'] ? next(false) : next(),
            children: [
                {
                    path: '',
                    name: 'ruleList',
                    component: RuleListView,
                    props: route => ({
                        queryAssets: route.query.assets
                            ?.split(',')
                            .map(i => parseInt(i)),
                        queryLocations: route.query.locations
                            ?.split(',')
                            .map(i => parseInt(i)),
                    }),
                },
                {
                    path: 'templates',
                    name: 'ruleTemplates',
                    component: RuleTemplatesView,
                },
                {
                    path: 'create',
                    name: 'ruleCreate',
                    props: true,
                    component: RuleEditView,
                },
                {
                    path: ':id',
                    name: 'ruleEdit',
                    component: RuleEditView,
                },
            ],
        },

        {
            path: '/login',
            component: PublicLayout,
            beforeEnter: (to, from, next) =>
                store.state.auth.jwt ? next('/') : next(),
            children: [
                {
                    path: '',
                    name: 'login',
                    component: LoginView,
                    props: true,
                },
            ],
        },

        {
            path: '/verify-password-reset',
            component: PublicLayout,
            beforeEnter: (to, from, next) =>
                store.state.auth.jwt ? next('/') : next(),
            children: [
                {
                    path: '',
                    name: 'passwordReset',
                    component: PasswordResetView,
                    props: route => ({
                        ...route.params,
                        ...route.query,
                    }),
                },
            ],
        },

        {
            path: '/share/:token',
            component: SharingLayout,
            children: [
                {
                    path: 'assets/:id',
                    name: 'sharedAssetDetails',
                    component: SharedAssetDetailsView,
                    props: true,
                },
            ],
        },

        {
            path: '/demo',
            children: [
                {
                    path: '',
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('auth/login', {
                            username: process.env.VUE_APP_DEMO_USERNAME,
                            password: process.env.VUE_APP_DEMO_PASSWORD,
                        })
                        await store.dispatch('auth/loadUserInfo')
                        store.commit('common/setDemoType', 'ax-track')
                        next(process.env.VUE_APP_DEMO_TARGET)
                        store.dispatch('common/loadData')
                    },
                },
                {
                    path: ':demoType',
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('auth/login', {
                            username: process.env.VUE_APP_DEMO_USERNAME,
                            password: process.env.VUE_APP_DEMO_PASSWORD,
                        })
                        store.commit('common/setDemoType', to.params.demoType)
                        switch (to.params.demoType) {
                            case 'ax-city':
                                next(process.env.VUE_APP_AX_CITY_TARGET)
                                break
                            case 'ax-sports':
                                store.commit('map/switchClusteringEnabled')
                                next(process.env.VUE_APP_AX_SPORTS_TARGET)
                                break
                            case 'ax-collect':
                                next(process.env.VUE_APP_AX_COLLECT_TARGET)
                                break
                            case 'ax-track':
                                next(process.env.VUE_APP_AX_TRACK_TARGET)
                                break
                            default:
                                store.commit('common/setDemoType', 'ax-track')
                                next(process.env.VUE_APP_DEMO_TARGET)
                                break
                        }
                        store.dispatch('common/loadData')
                    },
                },
            ],
        },

        // Catch all
        { path: '*', redirect: '/map' },
    ],
})

router.beforeEach(async (to, from, next) => {
    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        !(await store.dispatch('auth/refreshTokenIfNecessary'))
    ) {
        const params = to.path ? { nextUrl: { path: to.path } } : null
        next({ name: 'login', params })
        return
    }

    next()
})

export default router
