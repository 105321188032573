<template>
    <div class="lsva-layout">
        <nav>
            <LsvaMenu />
        </nav>

        <main>
            <router-view />
        </main>
    </div>
</template>

<script>
import LsvaMenu from '../components/LsvaMenu.vue'

export default {
    name: 'LsvaLayout',
    components: {
        LsvaMenu,
    },
}
</script>

<style lang="scss" scoped>
.lsva-layout {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;

    nav,
    main {
        height: 100%;
        overflow-y: auto;
    }

    nav {
        flex: 0 0 220px;
    }

    main {
        width: 100%;
        border-left: $style-border;
    }

    .bg {
        background-color: $color-bg-new;
    }

    @include respond-to('for-mobile-down') {
        flex-direction: column;

        nav,
        main {
            width: 100%;
            overflow-y: visible;
        }

        nav {
            border-bottom: $style-border;
        }

        main {
            border-left: none;
        }
    }
}
</style>
