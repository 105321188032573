<template>
    <div class="lsva-filter">
        <div class="lsva-filter__calendar">
            <!-- Selected Day Display -->
            <div class="lsva-filter__header">
                <h2 class="lsva-filter__header-title">
                    {{ formattedSelectedDate }}
                </h2>
                <span class="lsva-filter__header-week">
                    {{ $t('calendarWeek') }} {{ selectedWeek }}
                </span>
            </div>

            <!-- Date Navigation -->
            <div class="lsva-filter__days-container">
                <button
                    class="lsva-filter__nav-button"
                    :disabled="isLeftDisabled"
                    @click="scrollDays(-1)"
                >
                    <ChevronLeftIcon width="9" height="17" />
                </button>
                <div class="lsva-filter__days-wrapper">
                    <div class="lsva-filter__days">
                        <div
                            v-for="day in visibleDays"
                            :key="day.date"
                            class="lsva-filter__day"
                            :class="{
                                'lsva-filter__day--selected':
                                    day.date === selectedDate,
                                'lsva-filter__day--sunday': day.isSunday,
                            }"
                            @click="selectDate(day.date)"
                        >
                            <div class="lsva-filter__day-label">
                                {{ day.label }}
                            </div>
                            <div class="lsva-filter__day-number">
                                {{ day.dayNumber }}. {{ monthAbbr }}
                            </div>
                            <div
                                v-if="day.hasMarker"
                                class="lsva-filter__day-marker"
                            ></div>
                        </div>
                    </div>
                </div>
                <button
                    class="lsva-filter__nav-button"
                    :disabled="isRightDisabled"
                    @click="scrollDays(1)"
                >
                    <ChevronRightIcon width="9" height="17" />
                </button>
            </div>
        </div>
        <div class="lsva-filter__inputs">
            <div>
                <SearchField
                    v-model="searchQuery"
                    :placeholder="$t('searchForTrucks')"
                />
            </div>
            <div>
                <BaseMultiselect
                    v-model="status"
                    :options="statusOptions"
                    :custom-label="option => $t(option)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import ChevronLeftIcon from './icons/ChevronLeftIcon'
import ChevronRightIcon from './icons/ChevronRightIcon'
import BaseMultiselect from './redesigned/BaseMultiselect'
import SearchField from './SearchField'

const TOTAL_DAYS = 11 // Configurable constant for number of days to generate
const MAX_VISIBLE_DAYS = 7 // Configurable visible days

export default {
    name: 'DateSelect',
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        BaseMultiselect,
        SearchField,
    },
    props: {
        markerDates: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            days: [],
            selectedDate: this.formatDate(moment()), // Today's date
            visibleStartIndex: 0, // Calculated dynamically
            maxVisibleDays: MAX_VISIBLE_DAYS,
            statusOptions: [
                'driveAbroad',
                'driveInSwitzerland',
                'noDrive',
                'noContactWithTracker',
            ],
            status: '',
            searchQuery: '',
        }
    },
    computed: {
        visibleDays() {
            return this.days.slice(
                this.visibleStartIndex,
                this.visibleStartIndex + this.maxVisibleDays
            )
        },
        isLeftDisabled() {
            return this.visibleStartIndex === 0
        },
        isRightDisabled() {
            return (
                this.visibleStartIndex + this.maxVisibleDays >= this.days.length
            )
        },
        formattedSelectedDate() {
            return moment(this.selectedDate).format('dddd, D. MMMM')
        },
        selectedWeek() {
            return moment(this.selectedDate).isoWeek()
        },
        monthAbbr() {
            return moment().format('MMM')
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
        this.generateDays()
        this.visibleStartIndex = Math.max(
            this.days.length - this.maxVisibleDays,
            0
        )
    },
    methods: {
        generateDays() {
            for (let i = -TOTAL_DAYS; i <= 0; i++) {
                const date = moment().add(i, 'days')
                const formattedDate = this.formatDate(date)
                this.days.push({
                    date: formattedDate,
                    label: date.format('dd'),
                    dayNumber: date.date(),
                    isSunday: date.day() === 0, // Check if the day is Sunday
                    hasMarker: this.markerDates.includes(formattedDate),
                })
            }
        },
        formatDate(date) {
            return date.format('YYYY-MM-DD')
        },
        selectDate(date) {
            this.selectedDate = date
            this.$emit('date-selected', date)
        },
        scrollDays(direction) {
            this.visibleStartIndex = Math.min(
                Math.max(this.visibleStartIndex + direction, 0),
                this.days.length - this.maxVisibleDays
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "calendarWeek": "Calendar week",
        "searchForTrucks": "Search for trucks",
        "driveAbroad": "Drive abroad",
        "driveInSwitzerland": "Drive in Switzerland",
        "noDrive": "No drive",
        "noContactWithTracker": "No contact with tracker"
    },
    "de": {
        "calendarWeek": "Kalenderwoche",
        "searchForTrucks": "Suche nach Lastwagen",
        "driveAbroad": "Fahrt im Ausland",
        "driveInSwitzerland": "Fahrt in der Schweiz",
        "noDrive": "Keine Fahrt",
        "noContactWithTracker": "Kein Kontakt mit Tracker"
    },
    "fr": {
        "calendarWeek": "Semaine Civile",
        "searchForTrucks": "Recherche de camions",
        "driveAbroad": "Conduite à l'étranger",
        "driveInSwitzerland": "Conduite en Suisse",
        "noDrive": "Aucune conduite",
        "noContactWithTracker": "Aucun contact avec le tracker"
    },
    "it": {
        "calendarWeek": "Settimana di Calendario",
        "searchForTrucks": "Ricerca di camion",
        "driveAbroad": "Guida all'estero",
        "driveInSwitzerland": "Guida in Svizzera",
        "noDrive": "Nessuna guida",
        "noContactWithTracker": "Nessun contatto con il tracker"
    }
}
</i18n>

<style lang="scss" scoped>
.lsva-filter {
    &__calendar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__inputs {
        border-top: $style-border;
        padding: 16px 0;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }

    &__header {
        color: $color-primary;

        &-week {
            color: $color-primary-light;
        }
    }

    &__days-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 1px 1px 4px 0 #00000026;
    }

    &__days-wrapper {
        overflow: hidden;
        width: 100%;
    }

    &__days {
        display: flex;
    }

    &__day {
        flex: 1;
        width: 70px;
        text-align: center;
        padding: 8px;
        cursor: pointer;
        position: relative;
        background-color: $background-input;
        color: $color-primary;

        &--sunday {
            color: $color-blue-light;
        }

        &--selected {
            background-color: $color-primary;
            color: $color-primary-contrast;
        }

        &-label {
            font-weight: bold;
        }

        &-number {
            white-space: nowrap;
        }

        &-marker {
            width: 6px;
            height: 6px;
            background-color: $color-orange;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    &__nav-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
}
</style>
